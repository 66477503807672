<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h5>&nbsp;</h5>
      <b-dropdown :disabled="disabled" :text="$t('ADVANCED_SEARCH.OPTIONS')" variant="outline-primary">
        
        <b-dropdown-item @click.prevent="assign_value_clicked()">
          <span class="svg-icon svg-icon-md text-primary mr-2">
            <i class="fa fa-file color-primary"></i>
          </span>
          
          {{ $t('ADVANCED_SEARCH.ASSIGN_VALUES') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <MemberPropertyAssignEditor 
      :is_advanced_search="true"
      ref="assign-attribute-editor-modal"
      @attribute_selected="attribute_selected"
    />

  </div>

  
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import MemberPropertyAssignEditor from '@/view/components/member_editor_v2/sections/MemberPropertyAssignEditor.vue';

export default {
  name: 'advanced-search-export',
  mixins: [ toasts ],
  components: {
    MemberPropertyAssignEditor
  },
  props: ['column_names','search_options','disabled'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  data() {
    return {
      
    };
  },
  async mounted() {
    
  },
  watch: {
    
  },
  methods: {

    assign_value_clicked() {
      this.$refs['assign-attribute-editor-modal'].show();
    },

    async attribute_selected(item) {
      await this.post_attributes_from_search_options(item);
    },

    async post_attributes_from_search_options(item) {
      try {
        const res = await axios.post(`/search/member/attributes`, { search_options: this.search_options, attribute_item: item });

        if (res.status === 201) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }

      return [];
    },

  }
};
</script>
